












































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ProgramaticPointController'
import ProgramItem from './components/ProgramItem/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import { PROGRAMMATIC_POINT_PAGINATION } from '@/app/infrastructures/misc/Constants'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { Dictionary } from 'vue-router/types/router'

interface Parameter {
  page: number
  perPage: number
  keyword: null | string
  sortBy: { label: string; value: string }
  filterBy: { label: string; value: string }
}

interface Params {
  page: number
  perPage: number
  keyword: null | string
  sortBy: string
  filterBy: string
}

@Component({
  components: {
    ProgramItem,
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Loading,
    EmptyState,
  },
})
export default class ProgramaticPoint extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  filterOptions = [
    { label: 'Show All', value: 'SHOWALL' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Expired', value: 'EXPIRED' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ]
  sortOptions = [
    { label: 'Newest', value: 'NEWEST' },
    { label: 'Oldest', value: 'OLDEST' },
  ]
  parameters: Parameter = {
    page: 1,
    perPage: PROGRAMMATIC_POINT_PAGINATION,
    keyword: null,
    sortBy: this.sortOptions[0],
    filterBy: this.filterOptions[0],
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) || PROGRAMMATIC_POINT_PAGINATION,
        keyword: queries.keyword,
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterBy:
          this.filterOptions.find(item => item.value === queries.filterBy) ||
          this.filterOptions[0],
      }
    }

    this.fetchPrograms()
  }

  get params(): Params {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterBy: this.parameters.filterBy.value,
    }
  }

  @Watch('parameters.keyword')
  onKeywordChanged(val: string): void {
    val === '' && this.fetchPrograms(true)
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  private fetchPrograms(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    controller.getProgramaticList(this.params)
  }
}
