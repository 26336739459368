




















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Program } from '@/domain/entities/Program'
import Badge from '@/app/ui/components/Badge/index.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import {
  PARCEL_POINT_NAME,
  TriggerType,
  UserType,
} from '@/app/infrastructures/misc/Constants/programaticPoint'
import useProgrammaticPoint from '@/app/ui/views/Saldo/ProgramaticPoint/useCase'

@Component({
  components: {
    Badge,
  },
})
export default class ProgramItem extends Vue {
  useActionProgrammaticPoint = useProgrammaticPoint()
  @Prop() private isGuest!: boolean
  @Prop() private program!: Program

  get computedSaldoPoint(): number | string {
    if (this.program.percentOfTransaction) {
      return `${this.program.percentOfTransaction}%`
    }

    return Utils.currencyDigit(this.program.points || 0)
  }

  get computedMaxAmount(): number | string {
    return `Rp${Utils.currencyDigit(this.program.maxAmountGiven || 0)}`
  }

  get getBudgetUsedAndAmount(): number | string {
    return `Rp${Utils.currencyDigit(
      this.program.budgetUsed || 0
    )} / Rp${Utils.currencyDigit(this.program.budgetAmount || 0)}`
  }

  get statusBadgeType(): string {
    if (this.program?.status?.toLowerCase() === 'expired') {
      return 'expire'
    } else if (this.program?.status?.toLowerCase() === 'cancelled') {
      return 'cancelled'
    } else if (
      this.program?.status?.toLowerCase() === 'active' ||
      this.program?.status?.toLowerCase() === 'published'
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get titlePoint(): string {
    if (
      this.useActionProgrammaticPoint.isParcelPointCondition(
        this.program?.purpose,
        this.program?.triggerBy,
        this.program?.target
      )
    ) {
      return PARCEL_POINT_NAME
    }
    return 'Saldo Point'
  }

  private formatDate(date: string) {
    return Utils.formatTimeZone(Utils.formatDate(date))
  }
}
